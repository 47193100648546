import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { ActionReducerMapBuilder } from "@reduxjs/toolkit";

import {
  getCurrentUserAPI,
  getUserCountDetailsAPI,
  getUserSessionDetailsAPI,
} from "../../config/constants";

// Define a type for the slice state
interface reportState {
  userCountReport: any[];
  userSessionReport: any[];
  currentUserReport: any[];

  isSessionReportLoading: boolean;
  isUserCountLoading: boolean;
  totalUserCount: string;
  totalLoggedInUsersGrid: string;
  userSessionCount: string;

  isLoggedInUserLoading: boolean;
  totalLoggedInUsers: string;
}

// Define the initial state using that type
const initialState: reportState = {
  userCountReport: [],
  totalUserCount: "",
  isUserCountLoading: false,
  totalLoggedInUsersGrid: "",
  isSessionReportLoading: false,
  userSessionReport: [],
  userSessionCount: "",

  isLoggedInUserLoading: false,
  currentUserReport: [],
  totalLoggedInUsers: "",
};

export const getUserCountReport = createAsyncThunk(
  "userReports/getUserCountReport",
  async (payload: any) => {
    try {
      const response = await fetch(getUserCountDetailsAPI, {
        method: "POST",
        //credentials: "include",
        body: JSON.stringify(payload),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const result = await response.json();

      return result;
    } catch (err) {
      console.error(err);
    }
  }
);

export const getUserSessionReport = createAsyncThunk(
  "userReports/getUserSessionReport",
  async (payload: any) => {
    try {
      const response = await fetch(getUserSessionDetailsAPI, {
        method: "POST",
        //credentials: "include",
        body: JSON.stringify(payload),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const result = await response.json();

      return result;
    } catch (err) {
      console.error(err);
    }
  }
);

export const getCurrentUserReport = createAsyncThunk(
  "userReport/getCurrentUserReport",
  async (payload: any) => {
    try {
      const response = await fetch(getCurrentUserAPI, {
        method: "POST",
        //credentials: "include",
        body: JSON.stringify(payload),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const result = await response.json();

      return result;
    } catch (err) {
      console.error(err);
    }
  }
);

//for excel
export const getUserSessionReportForExcel = createAsyncThunk(
  "userReports/getUserSessionReportForExcel",
  async (payload: any) => {
    try {
      const response = await fetch(getUserSessionDetailsAPI, {
        method: "POST",
        //credentials: "include",
        body: JSON.stringify(payload),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const result = await response.json();

      return result;
    } catch (err) {
      console.error(err);
    }
  }
);

export const getUserCountReportForExcel = createAsyncThunk(
  "userReports/getUserCountReportForExcel",
  async (payload: any) => {
    try {
      const response = await fetch(getUserCountDetailsAPI, {
        method: "POST",
        //credentials: "include",
        body: JSON.stringify(payload),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const result = await response.json();

      return result;
    } catch (err) {
      console.error(err);
    }
  }
);

export const getCurrentUserReportForExcel = createAsyncThunk(
  "userReport/getCurrentUserReportForExcel",
  async (payload: any) => {
    try {
      const response = await fetch(getCurrentUserAPI, {
        method: "POST",
        //credentials: "include",
        body: JSON.stringify(payload),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const result = await response.json();

      return result;
    } catch (err) {
      console.error(err);
    }
  }
);

export const userReport = createSlice({
  name: "userReport",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<reportState>) => {
    builder.addCase(getUserCountReport.pending, (state, action) => {
      return {
        ...state,
        isUserCountLoading: true,
      };
    });
    builder.addCase(getUserCountReport.fulfilled, (state, action) => {
      return {
        ...state,
        userCountReport: action.payload?.data?.Usercount || [],
        totalUserCount: action.payload?.data?.UserGridcount || 0,
        isUserCountLoading: false,
      };
    });
    builder.addCase(getUserCountReport.rejected, (state, action) => {
      return {
        ...state,
        isUserCountLoading: false,
      };
    });

    //for excel
    builder.addCase(getUserCountReportForExcel.pending, (state, action) => {
      return {
        ...state,
        isUserCountLoading: true,
      };
    });
    builder.addCase(getUserCountReportForExcel.fulfilled, (state, action) => {
      return {
        ...state,
        isUserCountLoading: false,
      };
    });
    builder.addCase(getUserCountReportForExcel.rejected, (state, action) => {
      return {
        ...state,
        isUserCountLoading: false,
      };
    });

    builder.addCase(getUserSessionReport.pending, (state, action) => {
      return {
        ...state,
        isSessionReportLoading: true,
      };
    });
    builder.addCase(getUserSessionReport.fulfilled, (state, action) => {
      return {
        ...state,
        userSessionCount: action.payload?.data?.UserDetailscount,
        userSessionReport: action.payload?.data?.UserDetails || [],
        isSessionReportLoading: false,
      };
    });
    builder.addCase(getUserSessionReport.rejected, (state, action) => {
      return {
        ...state,
        isSessionReportLoading: false,
      };
    });

    //excel
    builder.addCase(getUserSessionReportForExcel.pending, (state, action) => {
      return {
        ...state,
        isSessionReportLoading: true,
      };
    });
    builder.addCase(getUserSessionReportForExcel.fulfilled, (state, action) => {
      return {
        ...state,
        isSessionReportLoading: false,
      };
    });
    builder.addCase(getUserSessionReportForExcel.rejected, (state, action) => {
      return {
        ...state,
        isSessionReportLoading: false,
      };
    });

    //currentuser
    builder.addCase(getCurrentUserReport.pending, (state, action) => {
      return {
        ...state,
        isLoggedInUserLoading: true,
      };
    });
    builder.addCase(getCurrentUserReport.fulfilled, (state, action) => {
      return {
        ...state,
        totalLoggedInUsers:
          action.payload?.data?.Usercount?.[0]?.UserCount || 0,
        totalLoggedInUsersGrid: action.payload?.data?.UserDetailsCount || 0,
        currentUserReport: action.payload?.data?.UserDetails || [],
        isLoggedInUserLoading: false,
      };
    });
    builder.addCase(getCurrentUserReport.rejected, (state, action) => {
      return {
        ...state,
        isLoggedInUserLoading: false,
      };
    });

    //excel
    builder.addCase(getCurrentUserReportForExcel.pending, (state, action) => {
      return {
        ...state,
        isLoggedInUserLoading: true,
      };
    });
    builder.addCase(getCurrentUserReportForExcel.fulfilled, (state, action) => {
      return {
        ...state,
        isLoggedInUserLoading: false,
      };
    });
    builder.addCase(getCurrentUserReportForExcel.rejected, (state, action) => {
      return {
        ...state,
        isLoggedInUserLoading: false,
      };
    });
  },
});

export default userReport.reducer;
