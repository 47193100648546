import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { ActionReducerMapBuilder, PayloadAction } from "@reduxjs/toolkit";

import {
  createUserRolesAPI,
  getAllUserRolesAPI,
  getUserRolesAPI,
  searchUserAPI,
  updateUserRolesAPI,
} from "../../config/constants";

// Define a type for the slice state
interface userRoleState {
  allUserRoles: any[];
  isUserRoleLoading: boolean;
  userRoles: any;
}

// Define the initial state using that type
const initialState: userRoleState = {
  allUserRoles: [],
  isUserRoleLoading: false,
  userRoles: {
    WWID: "",
    FirstName: "",
    LastName: "",
    isGuest: false,
    isSME: false,
    isScribe: false,
    isSRC: false,
    isHost: false,
    isTeamLeader: false,
    isTeamMember: false,
    isUserAdmin: false,
    isAdmin: false,
    isActive: false,
  },
};

export const getAllUserRoles = createAsyncThunk(
  "userRoles/getAllUserRoles",
  async (payload: any) => {
    try {
      const response = await fetch(getAllUserRolesAPI, {
        method: "POST",
        //credentials: "include",
        body: JSON.stringify(payload),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const result = await response.json();

      return result;
    } catch (err) {
      console.error(err);
    }
  }
);

export const getUserRoles = createAsyncThunk(
  "userRoles/getUserRoles",
  async (payload: any) => {
    try {
      const response = await fetch(getUserRolesAPI, {
        method: "POST",
        //credentials: "include",
        body: JSON.stringify(payload),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const result = await response.json();

      return result;
    } catch (err) {
      console.error(err);
    }
  }
);

export const searchUser = createAsyncThunk(
  "userRoles/searchUser",
  async (payload: any) => {
    try {
      const response = await fetch(searchUserAPI, {
        method: "POST",
        //credentials: "include",
        body: JSON.stringify(payload),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const result = await response.json();

      return result;
    } catch (err) {
      console.error(err);
    }
  }
);

export const updateUserRole = createAsyncThunk(
  "userRole/updateUserRole",
  async (payload: any) => {
    try {
      const response = await fetch(updateUserRolesAPI, {
        method: "PUT",
        //credentials: "include",
        body: JSON.stringify(payload),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const result = await response.json();

      return result;
    } catch (err) {
      console.error(err);
    }
  }
);

export const createNewUserRole = createAsyncThunk(
  "userRole/createNewUserRole",
  async (payload: any) => {
    try {
      const response = await fetch(createUserRolesAPI, {
        method: "POST",
        //credentials: "include",
        body: JSON.stringify(payload),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const result = await response.json();

      return result;
    } catch (err) {
      console.error(err);
    }
  }
);

export const userRoles = createSlice({
  name: "userRoles",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    localUpdateUserRoles(
      state,
      action: PayloadAction<{ roleName: string; value: boolean }>
    ) {
      const { roleName, value } = action.payload;
      state.userRoles = { ...state.userRoles, [roleName]: value };
    },
    resetAccess(state, action) {
      state.userRoles = {
        WWID: "",
        FirstName: "",
        LastName: "",
        isGuest: false,
        isSME: false,
        isScribe: false,
        isSRC: false,
        isHost: false,
        isTeamLeader: false,
        isTeamMember: false,
        isUserAdmin: false,
        isAdmin: false,
        isActive: false,
      };
    },
    resetUserAccess1(state, action: PayloadAction<{ newUserRoles: any }>) {
      state.userRoles = action.payload.newUserRoles;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<userRoleState>) => {
    builder.addCase(getAllUserRoles.pending, (state, action) => {
      return {
        ...state,
        isUserRoleLoading: true,
      };
    });
    builder.addCase(getAllUserRoles.fulfilled, (state, action) => {
      return {
        ...state,
        allUserRoles: action.payload?.data || [],
        isUserRoleLoading: false,
      };
    });
    builder.addCase(getAllUserRoles.rejected, (state, action) => {
      return {
        ...state,
        isUserRoleLoading: false,
      };
    });

    builder.addCase(getUserRoles.fulfilled, (state, action) => {
      return {
        ...state,
        userRoles: action.payload?.data ? action.payload.data : state.userRoles,
      };
    });

    builder.addCase(searchUser.fulfilled, (state, action) => {
      return {
        ...state,
        userRoles: action.payload?.data ? action.payload.data : state.userRoles,
      };
    });

    builder.addCase(createNewUserRole.fulfilled, (state, action) => {
      return {
        ...state,
      };
    });

    builder.addCase(updateUserRole.pending, (state, action) => {
      return {
        ...state,
        isUserRoleLoading: true,
      };
    });
    builder.addCase(updateUserRole.fulfilled, (state, action) => {
      return {
        ...state,
      };
    });
    builder.addCase(updateUserRole.rejected, (state, action) => {
      return {
        ...state,
        isUserRoleLoading: false,
      };
    });
  },
});

//export const { localUpdateUserRoles, resetAccess, setIsAdmin } = userRoles.actions;
export const { localUpdateUserRoles, resetAccess, resetUserAccess1 } =
  userRoles.actions;
export default userRoles.reducer;
