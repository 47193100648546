import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { ActionReducerMapBuilder, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { logoutAPI } from "../../config/constants";
import { settings } from "../../components/auth/auth-settings";
import { decrypt } from "../../encryption/encrypt-decrypt";

// Define a type for the slice state
interface loginState {
  isLoggedIn: boolean;
}

// Define the initial state using that type
const initialState: loginState = {
  isLoggedIn: false,
};

export function handleSSOLogout() {
  localStorage.clear();
  sessionStorage.clear();
  window.location.assign(
    `${settings.Authority}${decrypt(
      settings.Tenant_Id,
      settings.key
    )}//oauth2/v2.0/logout?post_logout_redirect_uri=${
      settings.Redirect_uri
    }/&client-request-id=${decrypt(settings.Client_Id, settings.key)}`
  );
}

export const logout = createAsyncThunk("login/logout", async (payload: any) => {
  try {
    const response = await fetch(logoutAPI, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json",
      },
    });
    const result = await response.json();
    return result;
  } catch (err) {
    console.error({ err });
  }
});

export const loginSlice = createSlice({
  name: "login",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    changeUserLoggedInStatus: (state, action: PayloadAction<boolean>) => {
      state.isLoggedIn = action.payload;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<loginState>) => {
    builder.addCase(logout.fulfilled, (state, action) => {
      return { ...state };
    });
  },
});

export const { changeUserLoggedInStatus } = loginSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectUserState = (state: RootState) => state.login.isLoggedIn;

export default loginSlice.reducer;
