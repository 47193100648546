import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { ActionReducerMapBuilder, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { addUpdateDocAPI, getDocAPI } from "../../config/constants";

// Define a type for the slice state
interface loginState {
  isLoggedIn: boolean;
  isDocDownloading: boolean;
}

// Define the initial state using that type
const initialState: loginState = {
  isLoggedIn: false,
  isDocDownloading: false,
};

export const getDoc = createAsyncThunk("doc/getDoc", async (payload: any) => {
  try {
    const response = await fetch(getDocAPI, {
      method: "POST",
      //credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json",
      },
    });
    const result = await response.json();

    return result;
  } catch (err) {
    console.error({ err });
  }
});

export const docSlice = createSlice({
  name: "doc",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    changeUserLoggedInStatus: (state, action: PayloadAction<boolean>) => {
      state.isLoggedIn = action.payload;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<loginState>) => {
    builder.addCase(getDoc.pending, (state, action) => {
      return { ...state, isDocDownloading: true };
    });
    builder.addCase(getDoc.fulfilled, (state, action) => {
      return { ...state, isDocDownloading: false };
    });
    builder.addCase(getDoc.rejected, (state, action) => {
      return { ...state, isDocDownloading: false };
    });
  },
});

export const { changeUserLoggedInStatus } = docSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectUserState = (state: RootState) => state.login.isLoggedIn;

export default docSlice.reducer;
