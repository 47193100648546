var CryptoJS = require("crypto-js");

function encrypt(msg, key) {
  return CryptoJS.AES.encrypt(msg, key).toString();
}

function decrypt(cipherText, key) {
  let message = CryptoJS.AES.decrypt(cipherText, key);
  return message.toString(CryptoJS.enc.Utf8);
}

//ldap
// console.log(
//   "ldap-clientid-",
//   encrypt("7ef01971-4623-40c7-898a-68fc20b72ff3", "atms-2024")
// );

module.exports = {
  encrypt,
  decrypt,
};
