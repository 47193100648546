import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { ActionReducerMapBuilder } from "@reduxjs/toolkit";

import {
  getScribeNoteAPI,
  insertScribeNoteAPI,
  getSingleAuditNoteAPI,
  updateAuditNoteAPI,
} from "./../../config/constants";

interface scribeState {
  scribeTypes: any[];
  InvestigatorList: any[];
  ScribeList: any[];
  HostList: any[];
  singleScribe: any[];
  userData: any[];
  isScribeLoading: boolean;
}

const initialState: scribeState = {
  scribeTypes: [],
  InvestigatorList: [],
  ScribeList: [],
  HostList: [],
  singleScribe: [],
  userData: [],
  isScribeLoading: false,
};

export const getAllScribe = createAsyncThunk(
  "scribe/getAllScribe",
  async (payload: any) => {
    try {
      const response = await fetch(getScribeNoteAPI, {
        method: "POST",
        //credentials: "include",
        body: JSON.stringify(payload),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const result = await response.json();
      return result;
    } catch (err) {
      console.error({ err });
    }
  }
);

export const addNewScribe = createAsyncThunk(
  "scribe/addNewScribe",
  async (payload: any) => {
    try {
      const response = await fetch(insertScribeNoteAPI, {
        method: "POST",
        //credentials: "include",
        body: JSON.stringify(payload),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const result = await response.json();
      return result;
    } catch (err) {
      console.error({ err });
    }
  }
);

export const getSingleAuditNote = createAsyncThunk(
  "scribe/getSingleAuditNote",
  async (payload: any) => {
    try {
      const response = await fetch(getSingleAuditNoteAPI, {
        method: "POST",
        //credentials: "include",
        body: JSON.stringify(payload),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const result = await response.json();
      return result;
    } catch (err) {
      console.error({ err });
    }
  }
);

export const updateAuditNote = createAsyncThunk(
  "scribe/updateAuditNote",
  async (payload: any) => {
    try {
      const response = await fetch(updateAuditNoteAPI, {
        method: "PUT",
        //credentials: "include",
        body: JSON.stringify(payload),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const result = await response.json();
      return result;
    } catch (err) {
      console.error({ err });
    }
  }
);

export const scribeSlice = createSlice({
  name: "scribe",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<scribeState>) => {
    builder.addCase(getAllScribe.pending, (state, action) => {
      return { ...state, isScribeLoading: true };
    });
    builder.addCase(getAllScribe.fulfilled, (state, action) => {
      return {
        ...state,
        scribeTypes: action.payload?.data?.scribeNotes || [],
        InvestigatorList: action.payload?.data?.InvestigatorList || [],
        ScribeList: action.payload?.data?.scribeList || [],
        HostList: action.payload?.data?.HostList || [],
        isScribeLoading: false,
      };
    });
    builder.addCase(getAllScribe.rejected, (state, action) => {
      return { ...state, isScribeLoading: false };
    });

    builder.addCase(addNewScribe.fulfilled, (state, action) => {
      return {
        ...state,
        isScribeLoading: false,
      };
    });
    builder.addCase(updateAuditNote.fulfilled, (state, action) => {
      return {
        ...state,
        isScribeLoading: false,
      };
    });

    builder.addCase(getSingleAuditNote.fulfilled, (state, action) => {
      return {
        ...state,
        singleScribe: action.payload?.data?.scribeNotes || [],
        userData: action.payload?.data?.userData || [],
        isScribeLoading: false,
      };
    });
  },
});

export default scribeSlice.reducer;
