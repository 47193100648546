import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import {
  getTickerMessageAPI,
  updateTickerMessageAPI,
} from "../../config/constants";

// Define a type for the slice state
interface tickerState {
  message: string;
  description: string;
}

// Define the initial state using that type
const initialState: tickerState = {
  message: "",
  description: "",
};

export const getTickerMessage = createAsyncThunk(
  "ticker/getTickerMessage",
  async () => {
    try {
      const response = await fetch(getTickerMessageAPI, {
        //credentials: "include",
      });
      const result = await response.json();
      return result;
    } catch (err) {
      console.error({ err });
    }
  }
);

export const updateTickerMessage = createAsyncThunk(
  "ticker/updateTickerMessage",
  async (payload: any) => {
    try {
      const response = await fetch(updateTickerMessageAPI, {
        method: "POST",
        //credentials: "include",
        body: JSON.stringify(payload),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const result = await response.json();
      return result;
    } catch (err) {
      console.error({ err });
    }
  }
);

export const tickerSlice = createSlice({
  name: "ticker",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<tickerState>) => {
    builder.addCase(getTickerMessage.fulfilled, (state, action) => {
      const data = action.payload?.data;
      return {
        ...state,
        message: data?.[0]?.TikerMessage || "",
        description: data?.[0]?.TikerDesctiption || "",
      };
    });
    builder.addCase(updateTickerMessage.fulfilled, (state, action) => {
      return { ...state };
    });
  },
});

export default tickerSlice.reducer;
