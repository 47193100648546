import React from "react";
import ReactDOM from "react-dom/client";
import "./global.scss";
import { RouterProvider } from "react-router-dom";
import { router } from "./routes";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { AuthenticateUser } from "./components/auth/AuthenticateUser";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <AuthenticateUser>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </AuthenticateUser>
);
