import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import type { ActionReducerMapBuilder } from "@reduxjs/toolkit";

import { colorThemeAPI } from "./../../config/constants";

interface submitState {
  color: any;
}

const initialState: submitState = {
  color: {},
};

export const getColor = createAsyncThunk(
  "colorTheme/getColor",
  async (payload: any) => {
    try {
      const response = await fetch(colorThemeAPI, {
        method: "POST",
        //credentials: "include",
        body: JSON.stringify(payload),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const result = await response.json();
      return result;
    } catch (err) {
      console.error(err);
    }
  }
);

export const colorThemeSlice = createSlice({
  name: "colorTheme",
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<submitState>) => {
    builder.addCase(getColor.fulfilled, (state, action) => {
      return { state, color: action.payload?.data };
    });
  },
});

export default colorThemeSlice.reducer;
