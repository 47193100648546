import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { ActionReducerMapBuilder, PayloadAction } from "@reduxjs/toolkit";

import {
  getAuditStatusAPI,
  updateAuditStatusAPI,
} from "../../config/constants";

// Define a type for the slice state
interface auditStatusState {
  auditStatus: any[];
  isAuditStatusLoading: boolean;
}

// Define the initial state using that type
const initialState: auditStatusState = {
  auditStatus: [],
  isAuditStatusLoading: false,
};

export const getAuditStatus = createAsyncThunk(
  "auditStatus/getAuditStatus",
  async (payload: any) => {
    try {
      const response = await fetch(getAuditStatusAPI, {
        method: "POST",
        //credentials: "include",
        body: JSON.stringify(payload),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const result = await response.json();

      return result;
    } catch (err) {
      console.error(err);
    }
  }
);

export const updateAuditStatus = createAsyncThunk(
  "auditStatus/updateAuditStatus",
  async (payload: any) => {
    try {
      const response = await fetch(updateAuditStatusAPI, {
        method: "PUT",
        //credentials: "include",
        body: JSON.stringify(payload),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const result = await response.json();

      return result;
    } catch (err) {
      console.error(err);
    }
  }
);

export const auditStatusSlice = createSlice({
  name: "auditStatusSlice",
  // `createSlice` will infer the state Status from the `initialState` argument
  initialState,
  reducers: {
    localUpdateAuditStatus(
      state,
      action: PayloadAction<{ auditId: string; name: string; value: string }>
    ) {
      const { auditId, name, value } = action.payload;
      state.auditStatus = state.auditStatus.map((audit) => {
        if (audit.AuditStatusID == auditId) {
          return { ...audit, [name]: value };
        }
        return audit;
      });
    },
    sortAuditStatus(state, action: PayloadAction) {
      state.auditStatus = state.auditStatus.sort(
        (a: any, b: any) => a.DisplayOrder - b.DisplayOrder
      );
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<auditStatusState>) => {
    builder.addCase(getAuditStatus.pending, (state, action) => {
      return {
        ...state,
        isAuditStatusLoading: true,
      };
    });
    builder.addCase(getAuditStatus.fulfilled, (state, action) => {
      return {
        ...state,
        isAuditStatusLoading: false,
        auditStatus:
          action?.payload?.auditStatus?.sort(
            (a: any, b: any) => a.DisplayOrder - b.DisplayOrder
          ) || [],
      };
    });
    builder.addCase(getAuditStatus.rejected, (state, action) => {
      return {
        ...state,
        isAuditStatusLoading: false,
      };
    });

    builder.addCase(updateAuditStatus.pending, (state, action) => {
      return {
        ...state,
        isAuditStatusLoading: true,
      };
    });
    builder.addCase(updateAuditStatus.fulfilled, (state, action) => {
      return {
        ...state,
        isAuditStatusLoading: false,
        auditStatus:
          action.payload?.data?.sort(
            (a: any, b: any) => a.DisplayOrder - b.DisplayOrder
          ) || [],
      };
    });
    builder.addCase(updateAuditStatus.rejected, (state, action) => {
      return {
        ...state,
        isAuditStatusLoading: false,
      };
    });
  },
});

export const { localUpdateAuditStatus, sortAuditStatus } =
  auditStatusSlice.actions;
export default auditStatusSlice.reducer;
