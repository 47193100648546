import { LoginType } from "react-aad-msal";
import { PublicClientApplication } from "@azure/msal-browser";

import { settings } from "./auth-settings";
import { decrypt } from "../../encryption/encrypt-decrypt";

// Msal Configurations
let options: any;

export const config = {
  auth: {
    authority: `${settings.Authority}${decrypt(
      settings.Tenant_Id,
      settings.key
    )}` as string,
    clientId: decrypt(settings.Client_Id, settings.key) as string,
    redirectUri: settings.Redirect_uri,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
};

// Authentication Parameters
export const authenticationParameters = {
  scopes: ["User.Read"],
};

export const msalInstance = new PublicClientApplication(config);

// Options
options = {
  loginType: LoginType.Redirect,
};
