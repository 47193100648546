export const settings = {
  Authority: "https://login.microsoftonline.com/",
  Tenant_Id: process.env.REACT_APP_TENENT_ID_ENCRYPTED,
  Client_Id: process.env.REACT_APP_CLIENT_ID_ENCRYPTED,
  Redirect_uri: process.env.REACT_APP_REDIRECT_URL,

  Client_Id_LDAP: process.env.REACT_APP_LDAP_CLIENT_ID_ENCRYPTED,
  Client_Secret_LDAP: process.env.REACT_APP_LDAP_SECRET_ID_ENCRYPTED,

  key: process.env.REACT_APP_SSO_KEY,
};
