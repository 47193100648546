import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import {
  addDetailsAPI,
  addNewRequestAPI,
  addNewRequestThreadAPI,
  deleteDocumentAPI,
  docReqIDMappingAPI,
  getAllAuditReqeustAPI,
  updateTeamMemberAPI,
  updateUrgentFlagAPI,
} from "../../config/constants";

// Define a type for the slice state
interface auditRequestState {
  auditRequests: {
    requestQueue: { auditRequestDetails: [] };
    InvestigatorList: any[];
    teamMemberList: [];
    hostList: any[];
    RequestStatusList: any[];
  };
  singleRequestData: {
    auditRequestDetails: any[];
    auditSubRequestDetails: any[];
    auditDocRequestDetails: any[];
  };
  isAuditRequestLoading: boolean;
  //isReqPending: boolean
  docReqMapping: any[];
}

// Define the initial state using that type
const initialState: auditRequestState = {
  docReqMapping: [],
  auditRequests: {
    requestQueue: { auditRequestDetails: [] },
    InvestigatorList: [],
    teamMemberList: [],
    hostList: [],
    RequestStatusList: [],
  },
  singleRequestData: {
    auditRequestDetails: [],
    auditSubRequestDetails: [],
    auditDocRequestDetails: [],
  },
  isAuditRequestLoading: false,
  //isReqPending: false
};

export const getAllAuditRequests = createAsyncThunk(
  "auditRequest/getAllAuditRequests",
  async (payload: any) => {
    try {
      const response = await fetch(getAllAuditReqeustAPI, {
        method: "POST",
        //credentials: "include",
        body: JSON.stringify(payload),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const result = await response.json();

      if (result.data?.requestQueue) {
        result.data.requestQueue = {
          ...result.data.requestQueue,
          //adding subReq to Main Req
          auditRequestDetails:
            result.data.requestQueue?.auditRequestDetails?.map(
              (request: any) => {
                let subRequests =
                  result.data.requestQueue.auditSubRequestDetails.filter(
                    (req: any) => req.AuditRequestID == request.AuditRequestID
                  );

                //adding doc part
                subRequests = subRequests.map((subReq: any) => {
                  let dcoThreadIDs = [];
                  dcoThreadIDs =
                    result.data.requestQueue?.auditDocRequestDetails.filter(
                      (doc: any) =>
                        subReq.RequestThreadID == doc.RequestThreadID &&
                        doc.DocThreadID != 0
                    );

                  return { ...subReq, dcoThreadIDs };
                });

                return { ...request, subRequests };
              }
            ) || [],
        };

        result.data.requestQueue = {
          ...result.data.requestQueue,
          auditRequestDetails:
            result.data.requestQueue.auditRequestDetails.sort(
              (a: any, b: any) => a.RequestStatusID - b.RequestStatusID
            ),
        };
      }

      return result;
    } catch (err) {
      console.error({ err });
    }
  }
);

export const updateTeamMember = createAsyncThunk(
  "auditRequest/updateTeamMember",
  async (payload: any) => {
    try {
      const response = await fetch(updateTeamMemberAPI, {
        method: "POST",
        //credentials: "include",
        body: JSON.stringify(payload),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const result = await response.json();
      return result;
    } catch (err) {
      console.error({ err });
    }
  }
);

export const updateUrgentFlag = createAsyncThunk(
  "auditRequest/updateUrgentFlag",
  async (payload: any) => {
    try {
      const response = await fetch(updateUrgentFlagAPI, {
        method: "POST",
        //credentials: "include",
        body: JSON.stringify(payload),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const result = await response.json();
      return result;
    } catch (err) {
      console.error({ err });
    }
  }
);

export const getSingleAuditRequest = createAsyncThunk(
  "auditRequest/getSingleAuditRequest",
  async (payload: any) => {
    try {
      const response = await fetch(addDetailsAPI, {
        method: "POST",
        //credentials: "include",
        body: JSON.stringify(payload),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const result = await response.json();
      return result;
    } catch (err) {
      console.error({ err });
    }
  }
);

export const addNewRequestThread = createAsyncThunk(
  "auditRequest/addNewRequestThread",
  async (payload: any) => {
    try {
      const response = await fetch(addNewRequestThreadAPI, {
        method: "POST",
        //credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
      const result = await response.json();
      return result;
    } catch (err) {
      console.error({ err });
    }
  }
);

export const addNewRequest = createAsyncThunk(
  "auditRequest/addNewRequest",
  async (payload: any) => {
    try {
      const response = await fetch(addNewRequestAPI, {
        method: "POST",
        //credentials: "include",
        body: JSON.stringify(payload),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const result = await response.json();
      return result;
    } catch (err) {
      console.error({ err });
    }
  }
);

export const docReqMapping = createAsyncThunk(
  "auditRequest/docReqMapping",
  async (payload: any) => {
    try {
      const response = await fetch(docReqIDMappingAPI, {
        method: "POST",
        //credentials: "include",
        body: JSON.stringify(payload),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const result = await response.json();
      return result;
    } catch (err) {
      console.error({ err });
    }
  }
);

export const deleteDocument = createAsyncThunk(
  "auditRequest/deleteDocument",
  async (payload: any) => {
    try {
      const response = await fetch(deleteDocumentAPI, {
        method: "POST",
        //credentials: "include",
        body: JSON.stringify(payload),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const result = await response.json();
      return result;
    } catch (err) {
      console.error({ err });
    }
  }
);

export const auditRequestSlice = createSlice({
  name: "auditRequest",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<auditRequestState>) => {
    builder.addCase(getAllAuditRequests.pending, (state, action) => {
      return { ...state, isAuditRequestLoading: true };
    });
    builder.addCase(getAllAuditRequests.fulfilled, (state, action) => {
      return {
        ...state,
        auditRequests: action.payload?.data || initialState.auditRequests,
        isAuditRequestLoading: false,
      };
    });
    builder.addCase(getAllAuditRequests.rejected, (state, action) => {
      return { ...state, isAuditRequestLoading: false };
    });

    builder.addCase(updateTeamMember.fulfilled, (state, action) => {
      return {
        ...state,
        isAuditRequestLoading: false,
      };
    });

    builder.addCase(updateUrgentFlag.fulfilled, (state, action) => {
      return {
        ...state,
        isAuditRequestLoading: false,
      };
    });

    builder.addCase(addNewRequestThread.fulfilled, (state, action) => {
      return {
        ...state,
        isAuditRequestLoading: false,
      };
    });

    builder.addCase(addNewRequest.pending, (state, action) => {
      return {
        ...state,
        isAuditRequestLoading: true,
      };
    });
    builder.addCase(addNewRequest.fulfilled, (state, action) => {
      return {
        ...state,
        isAuditRequestLoading: false,
      };
    });
    builder.addCase(addNewRequest.rejected, (state, action) => {
      return {
        ...state,
        isAuditRequestLoading: false,
      };
    });

    builder.addCase(getSingleAuditRequest.pending, (state, action) => {
      return {
        ...state,
        isAuditRequestLoading: true,
      };
    });
    builder.addCase(getSingleAuditRequest.fulfilled, (state, action) => {
      return {
        ...state,
        isAuditRequestLoading: false,
        // singleRequestData: action.payload?.data,
        singleRequestData: action.payload?.getsigngleRequestData,
      };
    });
    builder.addCase(getSingleAuditRequest.rejected, (state, action) => {
      return {
        ...state,
        isAuditRequestLoading: false,
      };
    });

    builder.addCase(deleteDocument.fulfilled, (state, action) => {
      return {
        ...state,
        isAuditRequestLoading: false,
      };
    });
    builder.addCase(docReqMapping.fulfilled, (state, action) => {
      return {
        ...state,
        isAuditRequestLoading: false,
        docReqMapping: action.payload?.data,
      };
    });
  },
});

export default auditRequestSlice.reducer;
