import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { ActionReducerMapBuilder, PayloadAction } from "@reduxjs/toolkit";

import {
  createAuditTypeAPI,
  getAuditTypeAPI,
  updateAuditTypeAPI,
} from "../../config/constants";

// Define a type for the slice state
interface auditTypeState {
  auditTypes: any[];
  isAuditTypeLoading: boolean;
}

// Define the initial state using that type
const initialState: auditTypeState = {
  auditTypes: [],
  isAuditTypeLoading: false,
};

export const getAuditType = createAsyncThunk(
  "auditType/getAuditType",
  async (payload: any) => {
    try {
      const response = await fetch(getAuditTypeAPI, {
        method: "POST",
        //credentials: "include",
        body: JSON.stringify(payload),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const result = await response.json();

      return result;
    } catch (err) {
      console.error(err);
    }
  }
);

export const updateAuditType = createAsyncThunk(
  "auditType/updateAuditType",
  async (payload: any) => {
    try {
      const response = await fetch(updateAuditTypeAPI, {
        method: "PUT",
        //credentials: "include",
        body: JSON.stringify(payload),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const result = await response.json();

      return result;
    } catch (err) {
      console.error(err);
    }
  }
);

export const auditTypeSlice = createSlice({
  name: "auditTypeSlice",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    localUpdateAuditType(
      state,
      action: PayloadAction<{ auditId: string; name: string; value: string }>
    ) {
      const { auditId, name, value } = action.payload;
      state.auditTypes = state.auditTypes.map((audit) => {
        if (audit.AuditTypeID == auditId) {
          return { ...audit, [name]: value };
        }
        return audit;
      });
    },
    sortAuditTypes(state, action: PayloadAction) {
      state.auditTypes = state.auditTypes.sort(
        (a: any, b: any) => a.DisplayOrder - b.DisplayOrder
      );
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<auditTypeState>) => {
    builder.addCase(getAuditType.pending, (state, action) => {
      return {
        ...state,
        isAuditTypeLoading: true,
      };
    });
    builder.addCase(getAuditType.fulfilled, (state, action) => {
      return {
        ...state,
        isAuditTypeLoading: false,
        auditTypes:
          action.payload?.auditTypes?.sort(
            (a: any, b: any) => a.DisplayOrder - b.DisplayOrder
          ) || [],
      };
    });
    builder.addCase(getAuditType.rejected, (state, action) => {
      return {
        ...state,
        isAuditTypeLoading: false,
      };
    });

    builder.addCase(updateAuditType.pending, (state, action) => {
      return {
        ...state,
        isAuditTypeLoading: true,
      };
    });
    builder.addCase(updateAuditType.fulfilled, (state, action) => {
      return {
        ...state,
        isAuditTypeLoading: false,
        auditTypes:
          action.payload?.data?.sort(
            (a: any, b: any) => a.DisplayOrder - b.DisplayOrder
          ) || [],
      };
    });
    builder.addCase(updateAuditType.rejected, (state, action) => {
      return {
        ...state,
        isAuditTypeLoading: false,
      };
    });
  },
});

export const { localUpdateAuditType, sortAuditTypes } = auditTypeSlice.actions;
export default auditTypeSlice.reducer;
