import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import {
  getAllAuditInfoAPI,
  createAuditAPI,
  getAuditDetails,
  getUserInfo,
  createInvestigatorAPI,
  updateAuditAPI,
  createUserRole,
  revokeUserRoleAPI,
  getAuditCountAPI,
} from "../../config/constants";
import { userInfo } from "os";

// Define a type for the slice state
interface auditState {
  allAuditInfo: any[];
  singleAuditInfo: any[];
  scribeList: any[];
  teamMemberList: any[];
  investigatorList: any[];
  userAdminList: any[];
  hostList: any[];
  teamLeadList: any[];
  SMEList: any[];
  guestList: any[];
  SRMList: any[];
  userInfo: any[];
  isAuditLoading: boolean;
  RoleData: { Req: any[] };
  isRefreshed: boolean;
  isUserInfoLoading: boolean;
  AuditCount: any[];
}

// Define the initial state using that type
const initialState: auditState = {
  allAuditInfo: [],
  singleAuditInfo: [],
  scribeList: [],
  teamMemberList: [],
  investigatorList: [],
  userAdminList: [],
  hostList: [],
  teamLeadList: [],
  SMEList: [],
  guestList: [],
  SRMList: [],
  userInfo: [],
  isAuditLoading: false,
  RoleData: { Req: [] },
  isRefreshed: false,
  isUserInfoLoading: false,
  AuditCount: [],
};

export const getAllAuditsInfo = createAsyncThunk(
  "audits/getAuditsInfo",
  async (CompanyID: any) => {
    try {
      const response = await fetch(`${getAllAuditInfoAPI}/${CompanyID}`, {
        //credentials: "include",
      });
      const result = await response.json();
      return result;
    } catch (err) {
      console.error({ err });
    }
  }
);

export const getAuditCount = createAsyncThunk(
  "audits/getAuditCount",
  async (payload: any) => {
    try {
      const response = await fetch(getAuditCountAPI, {
        method: "POST",
        //credentials: "include",
        body: JSON.stringify(payload),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const result = await response.json();
      return result;
    } catch (err) {
      console.error({ err });
    }
  }
);

export const createAudit = createAsyncThunk(
  "audits/createAudit",
  async (payload: any) => {
    try {
      const response = await fetch(createAuditAPI, {
        method: "POST",
        //credentials: "include",
        body: JSON.stringify(payload),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const result = await response.json();
      return result;
    } catch (err) {
      console.error({ err });
    }
  }
);

export const getauditDetails = createAsyncThunk(
  "audits/getauditDetails",
  async (payload: any) => {
    try {
      const response = await fetch(getAuditDetails, {
        method: "POST",
        //credentials: "include",
        body: JSON.stringify(payload),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const result = await response.json();
      return result;
    } catch (err) {
      console.error({ err });
    }
  }
);

export const UserInfo = createAsyncThunk(
  "audits/UserInfo",
  async (payload: any) => {
    try {
      const response = await fetch(getUserInfo, {
        method: "POST",
        //credentials: "include",
        body: JSON.stringify(payload),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const result = await response.json();
      return result;
    } catch (err) {
      console.error({ err });
    }
  }
);

export const createInvestigator = createAsyncThunk(
  "audits/createInvestigator",
  async (payload: any) => {
    try {
      const response = await fetch(createInvestigatorAPI, {
        method: "POST",
        //credentials: "include",
        body: JSON.stringify(payload),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const result = await response.json();
      return result;
    } catch (err) {
      console.error({ err });
    }
  }
);

export const updateAudit = createAsyncThunk(
  "audits/updateAudit",
  async (payload: any) => {
    try {
      const response = await fetch(updateAuditAPI, {
        method: "PUT",
        //credentials: "include",
        body: JSON.stringify(payload),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const result = await response.json();
      return result;
    } catch (err) {
      console.error({ err });
    }
  }
);

export const InsertUserRole = createAsyncThunk(
  "audits/InsertUserRole",
  async (payload: any) => {
    try {
      const response = await fetch(createUserRole, {
        method: "POST",
        //credentials: "include",
        body: JSON.stringify(payload),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const result = await response.json();
      return result;
    } catch (err) {
      console.error({ err });
    }
  }
);

export const revokeUserRole = createAsyncThunk(
  "audits/revokeUserRole",
  async (payload: any) => {
    try {
      const response = await fetch(revokeUserRoleAPI, {
        method: "PUT",
        //credentials: "include",
        body: JSON.stringify(payload),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const result = await response.json();
      return result;
    } catch (err) {
      console.error({ err });
    }
  }
);

export const auditSlice = createSlice({
  name: "audit",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    refreshPage(state, action: PayloadAction<{ val: boolean }>) {
      const { val } = action.payload;
      state.isRefreshed = val;
    },
    localUpdateUserInfo(
      state,
      action: PayloadAction<{ Req: string; value: any[] }>
    ) {
      const { Req, value } = action.payload;
      state.RoleData = { ...state.RoleData, [Req]: value };
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<auditState>) => {
    builder.addCase(getAllAuditsInfo.pending, (state, action) => {
      return { ...state, isAuditLoading: true };
    });
    builder.addCase(getAllAuditsInfo.fulfilled, (state, action) => {
      return {
        ...state,
        isAuditLoading: false,
        allAuditInfo: action.payload?.data || [],
      };
    });
    builder.addCase(getAllAuditsInfo.rejected, (state, action) => {
      return { ...state, isAuditLoading: false };
    });
    builder.addCase(createAudit.fulfilled, (state, action) => {
      return {
        ...state,
      };
    });
    builder.addCase(createInvestigator.fulfilled, (state, action) => {
      return {
        ...state,
      };
    });
    builder.addCase(updateAudit.fulfilled, (state, action) => {
      return {
        ...state,
      };
    });
    builder.addCase(UserInfo.pending, (state, action) => {
      return { ...state, isUserInfoLoading: true };
    });
    builder.addCase(UserInfo.fulfilled, (state, action) => {
      return {
        ...state,
        isUserInfoLoading: false,
        userInfo: action.payload?.data || [],
      };
    });
    builder.addCase(UserInfo.rejected, (state, action) => {
      return { ...state, isUserInfoLoading: false };
    });
    builder.addCase(getAuditCount.fulfilled, (state, action) => {
      return { ...state, AuditCount: action.payload?.data };
    });
    builder.addCase(getauditDetails.pending, (state, action) => {
      return { ...state, isAuditLoading: true };
    });
    builder.addCase(getauditDetails.fulfilled, (state, action) => {
      return {
        ...state,
        singleAuditInfo: action.payload?.data || [],
        scribeList: action.payload?.scribeList || [],
        teamMemberList: action.payload?.teamMemberList || [],
        investigatorList: action.payload?.InvestigatorList || [],
        userAdminList: action.payload?.userAdminList || [],
        hostList: action.payload?.HostList || [],
        teamLeadList: action.payload?.TeamLeadList || [],
        SMEList: action.payload?.SMEList || [],
        guestList: action.payload?.guestList || [],
        SRMList: action.payload?.srcList || [],
        isAuditLoading: false,
      };
    });
    builder.addCase(getauditDetails.rejected, (state, action) => {
      return { ...state, isAuditLoading: false };
    });
    builder.addCase(InsertUserRole.fulfilled, (state, action) => {
      return {
        ...state,
      };
    });
  },
});
export const { localUpdateUserInfo, refreshPage } = auditSlice.actions;
export default auditSlice.reducer;
