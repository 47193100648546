import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { ActionReducerMapBuilder } from "@reduxjs/toolkit";

import {
  createCompanyAPI,
  getAllCompaniesAPI,
  getCompanyAPI,
  preSignedURLAPI,
  updateCompnayAPI,
} from "../../config/constants";

// Define a type for the slice state
interface companyState {
  companies: any[];
  company: any;
  isCompanyLoading: boolean;
}

// Define the initial state using that type
const initialState: companyState = {
  companies: [],
  company: {},
  isCompanyLoading: false,
};

export const getAllCompanies = createAsyncThunk(
  "company/getAllCompanies",
  async () => {
    try {
      const response = await fetch(getAllCompaniesAPI, {
        //credentials: "include",
      });
      const result = await response.json();
      return result;
    } catch (err) {
      console.error({ err });
    }
  }
);

export const getCompany = createAsyncThunk(
  "company/getCompany",
  async (companyId: any) => {
    try {
      const response = await fetch(getCompanyAPI + companyId, {
        //credentials: "include",
      });

      const result = await response.json();
      return result;
    } catch (err) {
      console.error(err);
    }
  }
);

export const createNewCompanies = createAsyncThunk(
  "company/createNewCompany",
  async (payload: any) => {
    try {
      const response = await fetch(createCompanyAPI, {
        method: "POST",
        //credentials: "include",
        //body: payload,
        body: JSON.stringify(payload),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const result = await response.json();
      return result;
    } catch (err) {
      console.error({ err });
    }
  }
);

export const updateCompanies = createAsyncThunk(
  "company/updateCompany",
  async (payload: any) => {
    try {
      const response = await fetch(updateCompnayAPI, {
        method: "POST",
        //credentials: "include",
        //body: payload,
        body: JSON.stringify(payload),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const result = await response.json();
      return result;
    } catch (err) {
      console.error({ err });
    }
  }
);

export const getPreSignedURL = createAsyncThunk(
  "company/getPreSignedURL",
  async (payload: any) => {
    try {
      const response = await fetch(preSignedURLAPI, {
        method: "POST",
        //credentials: "include",
        body: JSON.stringify(payload),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const result = await response.json();
      return result;
    } catch (err) {
      console.error({ err });
    }
  }
);

export const companySlice = createSlice({
  name: "company",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<companyState>) => {
    builder.addCase(getAllCompanies.pending, (state, action) => {
      return { ...state, isCompanyLoading: true };
    });
    builder.addCase(getAllCompanies.fulfilled, (state, action) => {
      return {
        ...state,
        isCompanyLoading: false,
        companies: action.payload?.companies || [],
      };
    });
    builder.addCase(getAllCompanies.rejected, (state, action) => {
      return { ...state, isCompanyLoading: false };
    });

    builder.addCase(getCompany.fulfilled, (state, action) => {
      return { ...state, company: action.payload?.getSingleCompany?.[0] || {} };
    });
    builder.addCase(createNewCompanies.fulfilled, (state, action) => {});
    builder.addCase(updateCompanies.fulfilled, (state, action) => {});
    builder.addCase(getPreSignedURL.fulfilled, (state, action) => {});
  },
});

export default companySlice.reducer;
