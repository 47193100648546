import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { ActionReducerMapBuilder, PayloadAction } from "@reduxjs/toolkit";

import { getAuditSiteAPI, updateAuditSiteAPI } from "../../config/constants";

// Define a type for the slice state
interface auditSiteState {
  auditSite: any[];
  isAuditSiteLoading: boolean;
}

// Define the initial state using that type
const initialState: auditSiteState = {
  auditSite: [],
  isAuditSiteLoading: false,
};

export const getAuditSite = createAsyncThunk(
  "auditSite/getAuditSite",
  async (payload: any) => {
    try {
      const response = await fetch(getAuditSiteAPI, {
        method: "POST",
        //credentials: "include",
        body: JSON.stringify(payload),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const result = await response.json();

      return result;
    } catch (err) {
      console.error(err);
    }
  }
);

export const updateAuditSite = createAsyncThunk(
  "auditSite/updateAuditSite",
  async (payload: any) => {
    try {
      const response = await fetch(updateAuditSiteAPI, {
        method: "PUT",
        //credentials: "include",
        body: JSON.stringify(payload),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const result = await response.json();

      return result;
    } catch (err) {
      console.error(err);
    }
  }
);

export const auditSiteSlice = createSlice({
  name: "auditSiteSlice",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    localUpdateAuditSite(
      state,
      action: PayloadAction<{ auditId: string; name: string; value: string }>
    ) {
      const { auditId, name, value } = action.payload;
      state.auditSite = state.auditSite.map((audit) => {
        if (audit?.AuditSiteID == auditId) {
          return { ...audit, [name]: value };
        }
        return audit;
      });
    },
    sortAuditSite(state, action: PayloadAction) {
      state.auditSite = state.auditSite.sort(
        (a: any, b: any) => a.DisplayOrder - b.DisplayOrder
      );
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<auditSiteState>) => {
    builder.addCase(getAuditSite.pending, (state, action) => {
      return {
        ...state,
        isAuditSiteLoading: true,
      };
    });
    builder.addCase(getAuditSite.fulfilled, (state, action) => {
      return {
        ...state,
        isAuditSiteLoading: false,
        auditSite:
          action.payload?.auditSite?.sort(
            (a: any, b: any) => a.DisplayOrder - b.DisplayOrder
          ) || [],
      };
    });
    builder.addCase(getAuditSite.rejected, (state, action) => {
      return {
        ...state,
        isAuditSiteLoading: false,
      };
    });

    builder.addCase(updateAuditSite.pending, (state, action) => {
      return {
        ...state,
        isAuditSiteLoading: true,
      };
    });
    builder.addCase(updateAuditSite.fulfilled, (state, action) => {
      return {
        ...state,
        isAuditSiteLoading: false,
        auditSite:
          action.payload?.data?.sort(
            (a: any, b: any) => a.DisplayOrder - b.DisplayOrder
          ) || [],
      };
    });
    builder.addCase(updateAuditSite.rejected, (state, action) => {
      return {
        ...state,
        isAuditSiteLoading: false,
      };
    });
  },
});

export const { localUpdateAuditSite, sortAuditSite } = auditSiteSlice.actions;
export default auditSiteSlice.reducer;
