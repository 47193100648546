import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import {
  getAuditRequestReportsAPI,
  getDocumentReportsAPI,
} from "../../config/constants";

// Define a type for the slice state
interface reportState {
  auditReport: any[];
  isAuditReportLoading: boolean;
  documentReport: any[];
  isDocumentLoading: boolean;
}

// Define the initial state using that type
const initialState: reportState = {
  isAuditReportLoading: false,
  isDocumentLoading: false,
  auditReport: [],
  documentReport: [],
};

export const getDocumentTakenReport = createAsyncThunk(
  "reports/getDocumentReports",
  async (payload: any) => {
    try {
      const response = await fetch(getDocumentReportsAPI, {
        method: "POST",
        //credentials: "include",
        body: JSON.stringify(payload),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const result = await response.json();

      return result;
    } catch (err) {
      console.error(err);
    }
  }
);

export const getAuditRequestReport = createAsyncThunk(
  "reports/getAuditReports",
  async (payload: any) => {
    try {
      const response = await fetch(getAuditRequestReportsAPI, {
        method: "POST",
        //credentials: "include",
        body: JSON.stringify(payload),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const result = await response.json();

      return result;
    } catch (err) {
      console.error(err);
    }
  }
);

export const reportSlice = createSlice({
  name: "adminReport",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<reportState>) => {
    builder.addCase(getAuditRequestReport.pending, (state, action) => {
      return { ...state, isAuditReportLoading: true };
    });
    builder.addCase(getAuditRequestReport.fulfilled, (state, action) => {
      return {
        ...state,
        auditReport: action.payload?.getAllReports || [],
        isAuditReportLoading: false,
      };
    });
    builder.addCase(getAuditRequestReport.rejected, (state, action) => {
      return { ...state, isAuditReportLoading: false };
    });

    builder.addCase(getDocumentTakenReport.pending, (state, action) => {
      return { ...state, isDocumentLoading: true };
    });
    builder.addCase(getDocumentTakenReport.fulfilled, (state, action) => {
      return {
        ...state,
        documentReport: action.payload?.getAllReports || [],
        isDocumentLoading: false,
      };
    });
    builder.addCase(getDocumentTakenReport.rejected, (state, action) => {
      return { ...state, isDocumentLoading: false };
    });
  },
});

export default reportSlice.reducer;
