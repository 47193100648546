import { createBrowserRouter } from "react-router-dom";
import { lazy } from "react";

const DocUpload = lazy(() => import("./components/docUpload/docUpload"));

const Root = lazy(() => import("./components/root/root"));
const Help = lazy(() => import("./components/help/help"));
const PageNotFound = lazy(
  () => import("./components/pageNotFound/pageNotFound")
);
const SessionExpired = lazy(() => import("./components/session/session"));
const InvalidATMSUser = lazy(
  () => import("./components/invalidAtms/invalidATMSUser")
);

//Admin Menu
const AdminMenu = lazy(() => import("./components/admin/adminMenu/adminMenu"));
const LookupData = lazy(
  () => import("./components/admin/lookupUpdate/lookupUpdate")
);
const ContactAdmin = lazy(
  () => import("./components/admin/contactAdmin/contactAdmin")
);
const ManageTicker = lazy(
  () => import("./components/admin/manageTicker/manageTicker")
);

//company
const ManageCompany = lazy(
  () => import("./components/admin/manageCompany/allCompanies/manageCompany")
);
const UpdateCompany = lazy(
  () => import("./components/admin/manageCompany/updateCompany/updateCompany")
);
const CompanyRedirection = lazy(
  () => import("./components/admin/manageCompany/companyRedirection")
);

//UserMaintanance
const UserMaintanance = lazy(
  () =>
    import(
      "./components/admin/userMaintainance/userMaintanance/userMaintanance"
    )
);
const CreateNewUSer = lazy(
  () =>
    import("./components/admin/userMaintainance/createNewUser/createNewUser")
);

//Report
const AdminReports = lazy(
  () => import("./components/admin/adminReports/adminReports")
);
const DocumentTakenReport = lazy(
  () =>
    import(
      "./components/admin/adminReports/documentTakenReport/doucmentTakenReport"
    )
);
const AuditRequestReport = lazy(
  () =>
    import(
      "./components/admin/adminReports/auditRequestReport/auditRequestReport"
    )
);
const CurrentUserLoggedInReport = lazy(
  () =>
    import(
      "./components/admin/userReports/currentUserLoggedInReport/currentUserLoggedInReport"
    )
);
const UserCountReport = lazy(
  () => import("./components/admin/userReports/userCountReport/userCount")
);
const UserSessionReport = lazy(
  () =>
    import("./components/admin/userReports/userSessionReport/userSessionReport")
);

const AuditRequests = lazy(
  () => import("./components/audit/auditRequest/auditRequests")
);
const NewRequest = lazy(
  () =>
    import(
      "./components/audit/auditRequest/auditRequestHeader/newRequest/newRequest"
    )
);
const DualView = lazy(
  () =>
    import(
      "./components/audit/auditRequest/auditRequestHeader/dualReq/dualReqView"
    )
);
const ReqScrView = lazy(
  () =>
    import(
      "./components/audit/auditRequest/auditRequestHeader/reqScrView/reqScrView"
    )
);
const ScribeNote = lazy(
  () =>
    import(
      "./components/audit/auditRequest/auditRequestHeader/scribeNote/scribeNote"
    )
);
const NewScribe = lazy(
  () =>
    import(
      "./components/audit/auditRequest/auditRequestHeader/scribeNote/ScribeHeader/newScribe/newScribe"
    )
);
const DualScribe = lazy(
  () =>
    import(
      "./components/audit/auditRequest/auditRequestHeader/scribeNote/ScribeHeader/DualScribe/dualScribeView"
    )
);
const RequestReport = lazy(
  () =>
    import(
      "./components/audit/auditRequest/auditRequestHeader/RequestReport/requestReport"
    )
);
const ScribeReport = lazy(
  () =>
    import(
      "./components/audit/auditRequest/auditRequestHeader/scribeNote/ScribeHeader/ScribeReport/scribeReport"
    )
);
const AddDetails = lazy(
  () =>
    import(
      "./components/audit/auditRequest/auditRequestHeader/AddRequestDetails/addDetails"
    )
);
const ScribeUpdate = lazy(
  () =>
    import(
      "./components/audit/auditRequest/auditRequestHeader/scribeNote/ScribeHeader/ScribeUpdate/scribeUpdate"
    )
);
const PrintRequest = lazy(
  () => import("./components/audit/auditRequest/printRequest")
);

const AuditMenu = lazy(() => import("./components/audit/auditMenu/Audit_menu"));
const CreateAudit = lazy(
  () => import("./components/audit/auditMenu/createAudit")
);
const AuditDetails = lazy(
  () => import("./components/audit/auditMenu/auditDetails")
);
const UpdateDetails = lazy(
  () => import("./components/audit/auditMenu/updateDetails")
);
const InvestigatorInfo = lazy(
  () =>
    import(
      "./components/audit/auditMenu/AuditDetails/Investigator Info/invInfo"
    )
);
const HostInfo = lazy(
  () =>
    import("./components/audit/auditMenu/AuditDetails/HostInformation/hostInfo")
);
const ScribeInfo = lazy(
  () =>
    import("./components/audit/auditMenu/AuditDetails/ScribeInfo/scribeInfo")
);
const SRMInfo = lazy(
  () => import("./components/audit/auditMenu/AuditDetails/SRMInfo/srmInfo")
);
const TeamLeadInfo = lazy(
  () =>
    import(
      "./components/audit/auditMenu/AuditDetails/TeamLeadInfo/teamLeadInfo"
    )
);
const TeamMemberInfo = lazy(
  () =>
    import(
      "./components/audit/auditMenu/AuditDetails/TeamMembInfo/teamMembInfo"
    )
);
const SMEInfo = lazy(
  () => import("./components/audit/auditMenu/AuditDetails/SMEInfo/smeInfo")
);
const GuestInfo = lazy(
  () => import("./components/audit/auditMenu/AuditDetails/GuestInfo/guestInfo")
);
const UserAdminInfo = lazy(
  () =>
    import(
      "./components/audit/auditMenu/AuditDetails/UserAdminInfo/userAdminInfo"
    )
);
const AddList = lazy(
  () => import("./components/audit/auditMenu/AuditDetails/AddList/addList")
);

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    children: [
      { index: true, element: <AuditMenu /> },
      {
        path: "/auditMenu",
        element: <AuditMenu />,
      },
      {
        path: "/adminMenu",
        element: <AdminMenu />,
      },
      {
        path: "/help",
        element: <Help />,
      },
      {
        path: "/auditRequest/:auditId",
        element: (
          <AuditRequests
            reqScrView={false}
            isSubmitted={false}
            dualReqView={false}
          />
        ),
      },
      {
        path: "/adminMenu/adminReports",
        element: <AdminReports />,
      },
      {
        path: "/adminMenu/lookupUpdate",
        element: <LookupData />,
      },
      {
        path: "adminMenu/userMaintanance",
        element: <UserMaintanance />,
      },
      {
        path: "adminMenu/userMaintanance/createNewUser",
        element: <CreateNewUSer pageType="new" />,
      },
      {
        path: "adminMenu/userMaintanance/editUserAccess/:userId",
        element: <CreateNewUSer pageType="edit" />,
      },
      {
        path: "/adminMenu/contactAdmin",
        element: <ContactAdmin />,
      },
      {
        path: "/adminMenu/manageCompany",
        element: <ManageCompany />,
      },
      {
        path: "/adminMenu/manageCompany/createCompany",
        element: <UpdateCompany pageName="Create" />,
      },
      {
        path: "/adminMenu/manageCompany/updateCompany/:companyId",
        element: <UpdateCompany pageName="Update" />,
      },
      {
        path: "/auditRequest/newRequest",
        element: <NewRequest isSubmitted={false} dualReqView={false} />,
      },
      {
        path: "/auditRequest/dualViewReq",
        element: <DualView />,
      },
      {
        path: "/auditRequest/reqScrView",
        element: <ReqScrView />,
      },
      {
        path: "/auditRequest/addDetails/:reqId",
        element: <AddDetails />,
      },
      {
        path: "/auditRequest/scribeNote",
        element: (
          <ScribeNote
            isSubmitted={false}
            dualScribeView1={false}
            dualScribeView={false}
          />
        ),
      },
      {
        path: "/scribeNote/createScribeNote",
        element: <NewScribe isSubmitted={false} dualScribeView={false} />,
      },
      {
        path: "/scribeNote/dualScribeNote",
        element: <DualScribe />,
      },

      {
        path: "/adminMenu/manageTicker",
        element: <ManageTicker />,
      },
      {
        path: "/scribeNote/updateScribe/:scribeId",
        element: <ScribeUpdate />,
      },
      {
        path: "/adminMenu/currentUserLoggedInReport",
        element: <CurrentUserLoggedInReport />,
      },
      {
        path: "/adminMenu/userCountReport",
        element: <UserCountReport />,
      },
      {
        path: "/adminMenu/userSessionReport",
        element: <UserSessionReport />,
      },
      {
        path: "/createAudit",
        element: <CreateAudit />,
      },
      {
        path: "/auditDetails/:auditId",
        element: <AuditDetails />,
      },
      {
        path: "/updateDetails/:auditId",
        element: <UpdateDetails />,
      },
      {
        path: "/auditDetails/invInfo",
        element: <InvestigatorInfo />,
      },
      {
        path: "/auditDetails/hostInfo",
        element: <HostInfo />,
      },
      {
        path: "/auditDetails/scribeInfo",
        element: <ScribeInfo />,
      },
      {
        path: "/auditDetails/srmInfo",
        element: <SRMInfo />,
      },
      {
        path: "/auditDetails/teamLeadInfo",
        element: <TeamLeadInfo />,
      },
      {
        path: "/auditDetails/teamMembInfo",
        element: <TeamMemberInfo />,
      },
      {
        path: "/auditDetails/smeInfo",
        element: <SMEInfo />,
      },
      {
        path: "/auditDetails/guestInfo",
        element: <GuestInfo />,
      },
      {
        path: "/auditDetails/userAdminInfo",
        element: <UserAdminInfo />,
      },
    ],
  },
  {
    path: "/auditDetails/addList",
    element: <AddList />,
  },
  {
    path: "/company/CompanyRedirection/:companyId",
    element: <CompanyRedirection />,
  },
  {
    path: "adminMenu/adminReports/documentTakenReport/:auditId",
    element: <DocumentTakenReport />,
  },
  {
    path: "adminMenu/adminReports/auditRequestReport/:auditId",
    element: <AuditRequestReport />,
  },
  {
    path: "/invalidUser",
    element: <InvalidATMSUser />,
  },
  {
    path: "/auditRequest/requestReport/:auditID",
    element: <RequestReport />,
  },
  {
    path: "/scribeNote/scribeReport/:auditId",
    element: <ScribeReport />,
  },
  {
    path: "/printRequest/:auditId",
    element: <PrintRequest />,
  },
  {
    path: "/sessionexpired",
    element: <SessionExpired />,
  },
  {
    path: "/docUpload",
    element: <DocUpload />,
  },
  {
    path: "*",
    element: <PageNotFound />,
  },
]);
