//URLS
export const API_URL = process.env.REACT_APP_API;

//admin report
export const getDocumentReportsAPI = `${API_URL}adminReports/documentReport`;
export const getAuditRequestReportsAPI = `${API_URL}adminReports/auditRequestReport`;

//audit
export const getAllAuditInfoAPI = `${API_URL}audit/getAllAuditsByCompany`;

//user report
export const getCurrentUserAPI = `${API_URL}userSession/getCurrentUserDetails`;
export const getUserSessionDetailsAPI = `${API_URL}userSession/getUserSessionDetails`;
export const getUserCountDetailsAPI = `${API_URL}userSession/getUserCountDetails`;

//companies
export const getAllCompaniesAPI = `${API_URL}companies/getAllCompany/`;
export const getCompanyAPI = `${API_URL}companies/getCompany/`;
export const createCompanyAPI = `${API_URL}companies/insertCompany/`;
export const updateCompnayAPI = `${API_URL}companies/updateCompany`;

//auditSite
export const getAuditSiteAPI = `${API_URL}auditSite/getAuditSite/`;
export const createAuditSiteAPI = `${API_URL}auditSite/insertAuditSite/`;
export const updateAuditSiteAPI = `${API_URL}auditSite/updateAuditSite/`;

//auditType
export const getAuditTypeAPI = `${API_URL}auditType/getAuditType/`;
export const createAuditTypeAPI = `${API_URL}auditType/insertAuditType/`;
export const updateAuditTypeAPI = `${API_URL}auditType/updateAuditType/`;

//auditStatus
export const getAuditStatusAPI = `${API_URL}auditStatus/getAuditStatus/`;
export const createAuditStatusAPI = `${API_URL}auditStatus/insertAuditStatus/`;
export const updateAuditStatusAPI = `${API_URL}auditStatus/updateAuditStatus/`;

//userRoles
export const getAllUserRolesAPI = `${API_URL}users/getAllUserRoles`;
export const getUserRolesAPI = `${API_URL}users/getUserRole/`;
export const createUserRolesAPI = `${API_URL}audit/createNewUserRole/`;
export const updateUserRolesAPI = `${API_URL}audit/updateExistingUserRole/`;
export const searchUserAPI = `${API_URL}users/searchUser/`;

//ticker
export const getTickerMessageAPI = `${API_URL}manageTicker/getmanageTickerContent`;
export const updateTickerMessageAPI = `${API_URL}manageTicker/insertmanageTickerContent`;

//auditRequests
export const getAllAuditReqeustAPI = `${API_URL}auditRequest/getAuditRequest`;
export const updateTeamMemberAPI = `${API_URL}auditRequest/allocateTeamMember`;
export const updateUrgentFlagAPI = `${API_URL}auditRequest/changeRequestPriority`;
export const addDetailsAPI = `${API_URL}auditRequest/getSingleRequestDetails`;
export const deleteDocumentAPI = `${API_URL}auditRequestThread/deleteDoc`;
export const docReqIDMappingAPI = `${API_URL}auditRequestThread/getReqDocMapping`;

export const addNewRequestThreadAPI = `${API_URL}auditRequest/addNewRequestThread`;
export const addNewRequestAPI = `${API_URL}auditRequest/addNewRequest`;

//user login
export const getLoginAPI = `${API_URL}login/getlogin`;

//getAuditsByCompany
export const getAllAuditsByCompanyAPI = `${API_URL}companyAction/getCompanyDetails`;

//scribe note
export const getScribeNoteAPI = `${API_URL}auditNote/getauditNote`;
export const insertScribeNoteAPI = `${API_URL}auditNote/insertauditNote`;
export const getSingleAuditNoteAPI = `${API_URL}auditNote/getSingleAuditNote`;
export const updateAuditNoteAPI = `${API_URL}auditNote/updateauditNote`;

//const doc
export const addUpdateDocAPI = `${API_URL}auditRequestThread/addUpdateDoc`;
export const getDocAPI = `${API_URL}auditRequestThread/getDoc`;
//contact admin
export const getAdminContactAPI = `${API_URL}contactAdmin/sendMail`;

//logout
export const logoutAPI = `${API_URL}logout`;

//new and update audit
export const createInvestigatorAPI = `${API_URL}audit/createInverstigator`;
export const createUserRole = `${API_URL}audit/userRole`;
export const createAuditAPI = `${API_URL}audit/createNewAudit`;
export const getAuditCountAPI = `${API_URL}audit/getAuditCount`;
export const getAuditDetails = `${API_URL}audit/getAuditDetails`;
export const getUserInfo = `${API_URL}audit/getUserinfo`;
export const updateAuditAPI = `${API_URL}audit/updateAuditInfo`;
export const revokeUserRoleAPI = `${API_URL}audit/revokeRoleAccess`;

//userMaintenanceLDAPSync
export const getUserMasterTableAPIUrl = `${API_URL}userMaintenance/getAllUsers`;
export const updateUserMasterTableAPIUrl = `${API_URL}userMaintenance/updateUser`;
export const getLDAPSyncAccessTokenAPIUrl = `${API_URL}userMaintenance/getAccessToken`;
export const getADUserDataUrl = `${API_URL}userMaintenance/getADUserData`;
export const getSingleADUserDataAPI = `${API_URL}userMaintenance/getSingleADUserData`;

//color Theme
export const colorThemeAPI = `${API_URL}ColorTheme/getColorTheme`;

//company
export const companyS3UploadAPI = `${API_URL}s3CompanyUpload`;
export const preSignedURLAPI = `${API_URL}s3/getPreSignedURL`;

//getNewSession
export const getNewSessionAPI = `${API_URL}getSessionId`;
