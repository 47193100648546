import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { createAsyncThunk } from "@reduxjs/toolkit";
import type { ActionReducerMapBuilder } from "@reduxjs/toolkit";

import { getAdminContactAPI } from "./../../config/constants";

interface submitState {
  contactData: any;
}

const initialState: submitState = {
  contactData: {},
};

export const createContact = createAsyncThunk(
  "contactAdmin/createContact",
  async (payload: any) => {
    try {
      const response = await fetch(getAdminContactAPI, {
        method: "POST",
        //credentials: "include",
        body: JSON.stringify(payload),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const result = await response.json();
      return result;
    } catch (err) {
      console.error(err);
    }
  }
);

export const contactAdminSlice = createSlice({
  name: "contactAdmin",
  initialState,
  reducers: {
    localUpdatecontactAdmin(
      state,
      action: PayloadAction<{ name: string; value: string }>
    ) {
      const { name, value } = action.payload;
      state.contactData = { ...state.contactData, [name]: value };
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<submitState>) => {
    builder.addCase(createContact.fulfilled, (state, action) => {
      return state;
    });
  },
});

export const { localUpdatecontactAdmin } = contactAdminSlice.actions;
export default contactAdminSlice.reducer;
