import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { ActionReducerMapBuilder, PayloadAction } from "@reduxjs/toolkit";

import {
  getUserMasterTableAPIUrl,
  updateUserMasterTableAPIUrl,
  getLDAPSyncAccessTokenAPIUrl,
  getADUserDataUrl,
  getSingleADUserDataAPI,
} from "../../config/constants";

interface ldapSyncState {
  allUserData: any;
  ldapAccessToken: string;
  isLoading: boolean;
  userDataAD: any;
  allADData: any;
}

const initialState: ldapSyncState = {
  allUserData: {},
  ldapAccessToken: "",
  isLoading: false,
  userDataAD: [],
  allADData: [],
};

export const getAllUserMasterTableData = createAsyncThunk<any>(
  "/userMaintenance/getUserMasterTableData",
  async () => {
    try {
      let response = await fetch(`${getUserMasterTableAPIUrl}`, {
        method: "GET",
        mode: "cors",
        //credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      });
      response = await response.json();
      return response;
    } catch (err) {
      console.error(err);
    }
  }
);

export const updateUserMasterTable = createAsyncThunk(
  "/userMaintenance/updateUserMasterTable",
  async (payload: any) => {
    try {
      let response = await fetch(`${updateUserMasterTableAPIUrl}`, {
        method: "PUT",
        //credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
      response = await response.json();
      return response;
    } catch (err) {
      console.error(err);
    }
  }
);

export const getLDAPSyncAccessToken = createAsyncThunk(
  "/userMaintenance/getLDAPAccessToken",
  async (payload: any) => {
    try {
      let response = await fetch(`${getLDAPSyncAccessTokenAPIUrl}`, {
        method: "POST",
        mode: "cors",
        //credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ payload }),
      });
      response = await response.json();
      return response;
    } catch (err) {
      console.error(err);
    }
  }
);

export const getLatestUserDataFromAD = createAsyncThunk(
  "/userMaintenacne/getLatestUserDataFromAD",
  async (payload: any) => {
    try {
      let response = await fetch(`${getADUserDataUrl}`, {
        method: "POST",
        mode: "cors",
        //credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
      response = await response.json();
      return response;
    } catch (err) {
      console.error(err);
    }
  }
);

export const getSingleADUser = createAsyncThunk(
  "/userMaintenacne/getSingleADUser",
  async (payload: any) => {
    try {
      let response = await fetch(`${getSingleADUserDataAPI}`, {
        method: "POST",
        mode: "cors",
        //credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
      response = await response.json();
      return response;
    } catch (err) {
      console.error(err);
    }
  }
);

export const userMaintenanceLDAP = createSlice({
  name: "userMaintenacneLDAP",
  initialState,
  reducers: {
    setAllUsersAD(state, action: PayloadAction<{ data: any }>) {
      state.allADData = action.payload.data;
    },
    resetAccessToken(state, action: PayloadAction<{ token: string }>) {
      state.ldapAccessToken = action.payload.token;
    },
    resetLDAPData(state, action: PayloadAction<{ users?: any[] }>) {
      state.userDataAD = [];
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<ldapSyncState>) => {
    builder.addCase(getAllUserMasterTableData.pending, (state, action) => {
      return {
        ...state,
        isLoading: true,
      };
    });
    builder.addCase(getAllUserMasterTableData.fulfilled, (state, action) => {
      return {
        ...state,
        isLoading: false,
        allUserData: action.payload,
      };
    });
    builder.addCase(getAllUserMasterTableData.rejected, (state, action) => {
      return {
        ...state,
        isLoading: false,
      };
    });

    builder.addCase(getLDAPSyncAccessToken.pending, (state, action) => {
      return {
        ...state,
        isLoading: true,
      };
    });
    builder.addCase(getLDAPSyncAccessToken.fulfilled, (state, action: any) => {
      return {
        ...state,
        isLoading: false,
        ldapAccessToken: action.payload?.data?.access_token,
      };
    });

    builder.addCase(getLatestUserDataFromAD.pending, (state, action) => {
      return {
        ...state,
        isLoading: true,
      };
    });
    builder.addCase(getLatestUserDataFromAD.fulfilled, (state, action: any) => {
      return {
        ...state,
        isLoading: false,
        userDataAD: state.userDataAD.concat(action.payload?.data),
      };
    });
    builder.addCase(getSingleADUser.fulfilled, (state, action: any) => {
      return {
        ...state,
        isLoading: false,
        userDataAD: state.userDataAD.concat(action.payload?.data),
      };
    });

    builder.addCase(updateUserMasterTable.pending, (state, action) => {
      return {
        ...state,
        isLoading: true,
      };
    });
    builder.addCase(updateUserMasterTable.fulfilled, (state, action) => {
      return {
        ...state,
        isLoading: false,
      };
    });
  },
});

export const { setAllUsersAD, resetLDAPData, resetAccessToken } =
  userMaintenanceLDAP.actions;
export default userMaintenanceLDAP.reducer;
